#executionschart{
  width: 100%;
  height: 100%;
  background-color: #090f1a;
  padding: 10px;
  border-radius:  0px 0px 0px 12px;
}
.online_agent_count span {
    margin-top: 20px;
    font-weight: 700;
    font-size: 22px;
    line-height: 33px;
    color: #ffffff;
    margin-left: 15px;
    margin-bottom: 3px;
    display: inline-block;
  }
  .online_agent_count p {
    font-weight: 600;
    font-size: 16px;
    color: #8e97ad;
    margin-left: 10px;
  }
  .online_agent_count span {
    font-size: 20px;
  }
  .online_agent_count p {
    font-size: 15px;
  }
  .purple_border{
    background: #673ab7;
    border-radius: 16px;
    height: 39px;
    width: 13px;
  
  }
  .voilet_border{
    background: #9c27b0;
    border-radius: 16px;
    height: 39px;
    width: 13px;
   
  }
  .pink_border {
    background: #e91e63;
    border-radius: 16px;
    height: 39px;
    width: 13px;
   
  }
  .red_border {
    background: #f44336;
    border-radius: 16px;
    height: 39px;
    width: 13px;
    
  }
  .dashbord_main_title {
    font-weight: 500;
    font-size: 22px;
    color: #ffffff;
  }
  .dashbord_main_title {
    font-size: 22px;
  }