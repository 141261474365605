.role-btn-tbl {
  background-color: #040404;
  border-radius: 14px;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #ffab2d !important;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 14px 15px;
}

.edit-btn-tbl {
  background-color: rgb(28, 38, 64);
  border-radius: 14px;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: rgb(71, 173, 250) !important;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 14px 20px;
  border: medium none;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.btn-comn-dash {
  border: 0;
  outline: none;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff !important;
  display: inline-block;
  background-color: #c59d48;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 12%);
  border-radius: 12px;
  padding: 14px;
  text-transform: capitalize;
}

// .contenedorNuevoUsuario {
//   transform: translate(-50%, -50%);
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   z-index: 1050;
//   width: 100%;
//   height: 100%;
//   outline: 0;
//   transition: opacity 0.15s linear;
//   display: block;
//   padding-left: 9px;
//   .contenedorInterno {
//     max-width: 800px;
//     transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;

//   }
// }

.contenido {
  // width: 100%;
  // height: 100%;
  max-width: 800px;
  overflow: auto;
  padding: 30px;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;

  background-color: #090f1a;
  border: 1 px solid #090f1a;
  box-shadow: 18px 4px 35px rgba(0, 0, 0, 0.15);
  border-radius: 14px;
  display: -ms-flexbox;
  //display: flex;
  //-ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  outline: 0;
  .header {
    padding-top: 40px;
    padding: 1rem 1rem;
    .headerTitle {
      font-size: 28px;
      font-weight: 700;
      color: #ffffff;
      display: block;
    }
  }

  .bdr-grid {
    //text-align: center;
    background-color: rgb(22, 21, 41);
    border-color: rgb(171, 170, 194);
    border: 1px solid #464366;
    border-radius: 14px;
    transition: all 0.3s;
    padding: 15px;
    //flex-basis: 0;
    flex-wrap: 1;
    max-width: 100%;
    margin-bottom: 25px;
  }

  .comn-label-dash {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 1px;
    color: #c7c7c7;
    font-weight: 500;
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
    margin-bottom: 15px;
  }
  .input-style-users {
    background-color: #242933;
    border: 1px solid #464366;
    border-radius: 14px;
    font-weight: 600;
    transition: all 0.3s;
    height: 55px;
    color: #fff;
    font-size: 14px;
    padding: 0.375rem 0.75rem;
    width: 100%;
  }
  .input-style-users:active,
  .input-style-users:hover {
    border: 1px solid #c59d48;
  }
  .inputEnvolt {
    border: 1px solid #464366;
    display: block;
    width: 100%;
    border-radius: 14px;
  }
  .inputEnvolt:active{
    border: 1px solid #fff;
  }

  .inputEnvolt:hover {
    border: 1px solid #c59d48;
  }
  .input-style-inputPassword {
    background-color: #242933;
    border: 1px solid transparent;
    font-weight: 600;
    height: 55px;
    transition: all 0.3s;
    color: #fff;
    font-size: 14px;
    width: 100%;
    display: inline-block;
    padding: 7px;    
    border-radius: 14px;
  }
  .input-style-inputPassword:active {
    border: 1px solid transparent;
  }
  .input-style-users {
    background-color: #242933;
    border: 1px solid #464366;
    border-radius: 14px;
    font-weight: 600;
    transition: all 0.3s;
    height: 55px;
    color: #fff;
    font-size: 14px;
    padding: 0.375rem 0.75rem;
    width: 100%;
  }
  .input-style-users:active,
  .input-style-users:hover {
    border: 1px solid #c59d48;
  }
  .btn-comn-dash {
    border: 0;
    outline: none;
    font-weight: 600;
    font-size: 16px;
    color: #ffffff !important;
    display: inline-block;
    background-color: #c59d48;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 12%);
    border-radius: 12px;
    padding: 10px;
    text-transform: capitalize;
    width: 100%;
    margin-top: 1rem;
  }
}

.MuiDataGrid-menuList {
  color: white;
  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    min-height: auto;
    font-weight: 400;
    font-size: 0.875rem;
  }
}
.css-jzcd7z-MuiFormControlLabel-root .MuiFormControlLabel-label{
  margin-left: 15px;
}
.css-11bfvty-MuiToolbar-root-MuiTablePagination-toolbar{
  color:white;
  button{
    color:white;
  }
}
.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label.Mui-disabled {
  color: rgba(255, 255, 255, 0.9);
}