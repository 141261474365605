.node-title {
  dx: -200;
  fill: green;
}

.node-label {
  font-size: small;
}

.BackImage {
  border: 1px solid #090f1a;
  box-shadow: 18px 4px 35px rgb(0 0 0 / 15%);
  border-radius: 14px;
  width: 80%;
  background-image: url("../../assets/images/grid.png");
}
