:root {
  --VeryDark: "#280c27";
  --Dark: "#5e1d5b";
  --Normal: "#872A83";
  --Light: "#C184BE";
  --VeryLight: "#E6CDE5";
  --Info: "#368DEF";
  --Error: "#EF3636";
  --Success: "#00B04A";
  --BgGeneral: "#11172E";
  --PaperColor: "#21273F";
}

a:-webkit-any-link {
  color: white;
  cursor: pointer;
  text-decoration: underline;
}
.MuiMenu-list {
  background-color: #0a0818;
  color: #fff;
}
.rt-icon-header {
  width: 22px;
  margin-right: 10px;
}
.App {
  text-align: center;
  background: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.Details {
  position: absolute;
  top: 25%;
  left: 25%;
  width: 50%;
  margin-top: -150px;
  border-radius: 16px;
  border: 8px solid #214a80;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2);
  padding: 5px;
  text-align: center;
  background-color: #1e2833;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vmin);
  color: white;
  overflow: auto;
}

.Center {
  background-color: #0a0818;
  min-height: 100vh;
  font-size: calc(16px + 2vmin);
  color: white;
  width: 100%;
}
.Login {
  max-width: 320px;
  width: 90%;
  background-color: #1e2833;
  padding: 40px;
  border-radius: 4px;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2);
  font-size: calc(16px + 2vmin);
  .InputText {
    background: none;
    border: none;
    border-bottom: 1px solid #434a52;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    color: inherit;
    display: block;
    width: 70%;
    margin: auto;
    margin-top: 16px;
  }
  .ButtonSubmit {
    background: #214a80;
    border: none;
    border-radius: 4px;
    padding: 11px;
    box-shadow: none;
    margin-top: 26px;
    text-shadow: none;
    outline: none;
    cursor: pointer;
  }
  .ButtonSubmit:hover,
  .ButtonSubmit:active {
    background: #2d64ad;
    outline: none;
  }
  .forgot {
    margin-top: 16px;
    display: block;
    text-align: center;
    font-size: 12px;
    color: #6f7a85;
    opacity: 0.9;
    text-decoration: none;
  }
  .forgot:hover,
  .forgot:active {
    opacity: 1;
    text-decoration: none;
  }
}

.Section {
  width: 60%;
  overflow: auto;
  background-color: #242933;
  padding: 15px;
  border-radius: 4px;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2);
  font-size: calc(16px + 2vmin);
  .InputText,
  .TextArea {
    background: none;
    border: none;
    border-bottom: 1px solid #434a52;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    color: inherit;
    display: block;
    margin: auto;
    margin-top: 8px;
  }
  .ButtonSubmit {
    background: #214a80;
    border: none;
    border-radius: 4px;
    padding: 11px;
    box-shadow: none;
    margin-top: 26px;
    text-shadow: none;
    outline: none;
    cursor: pointer;
    margin-left: 8px;
  }
  .ButtonSubmit:hover,
  .ButtonSubmit:active {
    background: #2d64ad;
    outline: none;
  }
}

.SectionPackageDetails {
  width: 60%;
  max-height: 800px;
  // height: 95%;
  overflow: auto;
  background-color: #242933;
  padding: 15px;
  border-radius: 4px;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2);
  font-size: calc(16px + 2vmin);
  .InputText,
  .TextArea {
    background: none;
    border: none;
    border-bottom: 1px solid #434a52;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    color: inherit;
    display: block;
    margin: auto;
    margin-top: 8px;
  }
  .ButtonSubmit {
    background: #214a80;
    border: none;
    border-radius: 4px;
    padding: 11px;
    box-shadow: none;
    margin-top: 26px;
    text-shadow: none;
    outline: none;
    cursor: pointer;
    margin-left: 8px;
  }
  .ButtonSubmit:hover,
  .ButtonSubmit:active {
    background: #2d64ad;
    outline: none;
  }
}
.Addpackage {
  overflow: auto;
  background-color: #1e2833;
  padding: 40px;
  border-radius: 4px;

  position: absolute;
  width: 60%;
  color: #fff;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2);
  font-size: calc(16px + 2vmin);
  .InputFile,
  .TextArea {
    background: none;
    border: none;
    border-bottom: 1px solid #434a52;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    color: #2f3cf3;
  }

  .textfield {
    input {
      color: white;
      background-color: #18212e;
      border-radius: 2px;
    }
    .MuiSvgIcon-root {
      fill: white;
      background-color: #18212e;
    }
  }
  .MuiSlider-markLabel {
    color: gray;
  }
  .MuiSlider-markLabelActive {
    color: white;
  }
}
.AddArtefact {
  max-height: 600px;
  overflow: auto;
  background-color: #1e2833;
  padding: 40px;
  border-radius: 4px;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2);
  font-size: calc(16px + 2vmin);
  .InputFile,
  .TextArea {
    background: none;
    border: none;
    border-bottom: 1px solid #434a52;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    color: inherit;
  }
  .ButtonSubmit {
    background: #214a80;
    border: none;
    border-radius: 4px;
    padding: 11px;
    box-shadow: none;
    margin-top: 26px;
    text-shadow: none;
    outline: none;
    cursor: pointer;
  }
  .ButtonSubmit:hover,
  .ButtonSubmit:active {
    background: #2d64ad;
    outline: none;
  }
  label {
    font-size: calc(16px + 2vmin);
  }
}
.realTimeDiv {
  .MuiDataGrid-columnHeader,
  .MuiDataGrid-cell,
  .MuiTablePagination-toolbar {
    color: #fff;
  }
  .MuiSvgIcon-root {
    color: #fff;
    fill: #fff;
  }
  .MuiStepLabel-label {
    color: #898da3;
  }
}

.TagTest {
  border-radius: 14px;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 14px 10px;
  width: 125px;
  &.Success {
    color: #38e25d !important;
  }
  &.PartiallyTested {
    color: #ff4747 !important;
  }
  &.untested {
    color: #ffab2d !important;
  }
}

.ThreatType {
  border-radius: 4px;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  transform: rotate(45deg);
  margin: 6px;
  &.Critical {
    background-color: #6a0000;
  }
  &.High {
    background-color: #ff0000;
  }
  &.Midd {
    background-color: #ff5830;
  }
  &.Low {
    background-color: #ffffa2;
  }
  &.None {
    background-color: #fff;
  }
  &.ZeroDay {
    background-color: red;
  }
}

.PackageType {
  border-radius: 14px;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 14px 10px;
  width: 125px;
  &.Zero {
    color: #ff4747 !important;
  }
  &.Challenger {
    color: #ff7e35 !important;
  }
  &.Unknown {
    color: #ffab2d !important;
  }
  &.Interactiva {
    color: #fff !important;
  }
  &.Known {
    color: #8c30ff !important;
  }
  &.Undefined {
    color: #898da3 !important;
  }
}
.css-1fgh12t {
  border: 1px solid #fff;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  min-height: auto;
  color: white;
}
.css-j7qwjs {
  background-color: #2a353f;
  color: #fff;
  .css-1w13o7u-MuiTypography-root {
    color: gray;
  }
  .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root {
    background-color: transparent;
  }
  .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-disabled {
    color: #fff;
  }
  .css-h7tmkn {
    color: gray;
  }
}

.css-ume8vi-MuiInputBase-input-MuiInput-input::before {
  border-bottom: 1px solid transparent;
  left: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  right: 0;
  -webkit-transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  pointer-events: none;
}
.iconButtonSize {
  width: 40px;
  height: 40px;
}
