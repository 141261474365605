.scene {
    border: 1px solid #CCC;
    margin: 40px 0;
    position: relative;
    width: 210px;
    height: 140px;
    margin: 80px auto;
    perspective: 1000px;
  }
  
  .carousel {
    width: 100%;
    height: 100%;
    position: absolute;
    transform: translateZ(-288px);
    transform-style: preserve-3d;
    transition: transform 1s;
  }
  
  .carousel__cell {
    position: absolute;
    width: 190px;
    height: 120px;
    left: 10px;
    top: 10px;
    border: 2px solid black;
    line-height: 116px;
    font-weight: bold;
    color: white;
    text-align: center;
    transition: transform 1s, opacity 1s;
  }
  
  .carousel__cell:nth-child(9n+1) {background-color: #17141d;
    border-radius: 10px;
    box-shadow: -1rem 0 3rem #000; }
  .carousel__cell:nth-child(9n+2) { background-color: #17141d;
    border-radius: 10px;
    box-shadow: -1rem 0 3rem #000; }
  .carousel__cell:nth-child(9n+3) { background-color: #17141d;
    border-radius: 10px;
    box-shadow: -1rem 0 3rem #000; }
  .carousel__cell:nth-child(9n+4) { background-color: #17141d;
    border-radius: 10px;
    box-shadow: -1rem 0 3rem #000; }
  .carousel__cell:nth-child(9n+5) { background-color: #17141d;
    border-radius: 10px;
    box-shadow: -1rem 0 3rem #000; }
  .carousel__cell:nth-child(9n+6) { background-color: #17141d;
    border-radius: 10px;
    box-shadow: -1rem 0 3rem #000; }
  .carousel__cell:nth-child(9n+7) { background-color: #17141d;
    border-radius: 10px;
    box-shadow: -1rem 0 3rem #000; }
  .carousel__cell:nth-child(9n+8) { background-color: #17141d;
    border-radius: 10px;
    box-shadow: -1rem 0 3rem #000; }
  .carousel__cell:nth-child(9n+0) { background-color: #17141d;
    border-radius: 10px;
    box-shadow: -1rem 0 3rem #000; }
  
  .carousel__cell:nth-child(1) { transform: rotateY(  0deg) translateZ(288px); }
  .carousel__cell:nth-child(2) { transform: rotateY( 40deg) translateZ(288px); }
  .carousel__cell:nth-child(3) { transform: rotateY( 80deg) translateZ(288px); }
  .carousel__cell:nth-child(4) { transform: rotateY(120deg) translateZ(288px); }
  .carousel__cell:nth-child(5) { transform: rotateY(160deg) translateZ(288px); }
  .carousel__cell:nth-child(6) { transform: rotateY(200deg) translateZ(288px); }
  .carousel__cell:nth-child(7) { transform: rotateY(240deg) translateZ(288px); }
  .carousel__cell:nth-child(8) { transform: rotateY(280deg) translateZ(288px); }
  .carousel__cell:nth-child(9) { transform: rotateY(320deg) translateZ(288px); }
  
  
  
  .carousel-options {
    text-align: center;
    position: relative;
    z-index: 2;
    background: hsla(0, 0%, 100%, 0.8);
  }