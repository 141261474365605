#Top5ArtifactsSuccessfullyEvadedNetworkSecurityChart{
  width: 100%;
  height: 100%;
  background-color: #090f1a;
  padding: 10px;
  border-radius:  0px 0px 12px 12px;
}
.exc_first_menu {
    vertical-align: middle;
    display: inline-flex;
    align-items: center;
  }
.exc_art_menu ul li {
    margin-top: 15px;
  }

  .pur_sequer {
    background: #6418c3;
    border-radius: 6.19211px;
    height: 18px;
    width: 18px;
    display: inline-block;
    margin-right: 6px;
  }
  
  .exc_text {
    font-weight: 600;
    font-size: 15px;
    color: #ffffff;
    margin-left: 10px;
  }
  .exc_text {
    font-size: 13px;
    margin-left: 5px;
  }
  .ext_count {
    display: inline-block;
    float: right;
    position: relative;
    top: -3px;
  }
  .ext_count span {
    font-weight: 600;
    font-size: 20px;
    color: #ffffff;
  }
  .ext_count span {
    font-size: 18px;
  }