
.avatar-upload {
   .avatar-edit {
       input {
           display: none;
           + label {
               display: inline-block;
               width: 34px;
               height: 34px;
               border-radius: 100%;
               margin-top: 7px;
               cursor: pointer;
               font-weight: normal;
               transition: all .2s ease-in-out;
               text-align: center;
              
           }
       }
   }
}