#Top10MostUsedAgentsDiv{
  width: 100%;
  height: 100%;
  background-color: #090f1a;
  border-radius: 0px 0px 12px 12px,

  }
  .column-color{
    width: 18px;
    height: 18px;
    min-width: 15px;
    border-radius: 5px;
  }
  
  .column-legend{
    padding-left: 50px;
    padding-right: 20px;
    padding-bottom: 20px;
    background-color: #090f1a;
    border-radius: 0px 0px 12px 12px;
  }
  .containerli{
    margin: auto;
    font-size: 14px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
  
  }
  
  .containerli span{
    margin: 6px;
    display: flex;
    flex-wrap: wrap;
  }
  .containerli > span > bdi{
    word-break: break-word;
    margin-bottom: 5px;
    color: #fff;
    max-width: 20%;
    text-transform: capitalize;
    display: inline-block;
    align-items: center;
  }