$greyBase: #becbd3;

@mixin backGray($deg) {
    background: $greyBase;
    background: linear-gradient($deg, #ffffff 0%, #dadada 100%);
}
@mixin backGrayLight($deg) {
    background: $greyBase;
    background: linear-gradient($deg, #ffffff 0%, #dadada 40%);
}
@mixin backGrayLighter($deg) {
    background: #ffffff;
    background: linear-gradient($deg, #ffffff 0%, #ebebeb 100);
}
@mixin backBlue($deg) {
    background: #025088;
    background: linear-gradient($deg, #0073df 0%, #025088 100%);
}
@mixin backBluelight($deg) {
    background: #0f599e;
    background: linear-gradient($deg, #5db1ff 0%, #0f599e 20%);
}
@mixin backBluelightMedium($deg) {
    background: #0f599e;
    background: linear-gradient($deg, #5db1ff 0%, #0f599e 100%);
}
.PDFTitlePDF {
    position: fixed;
    z-index: 1000;
    margin-top: 150px;
    margin-left: 50px;
    font-size: 50px;
    color: #455763;
}
.PDFMain {
    z-index: -1000;
    @include backGray(180deg);
    width: 595px;
    height: 842px;
    overflow: hidden;
    position: relative;
    .PDFRectOne {
        width: 350px;
        height: 350px;
        @include backGray(90deg);
        transform: translate(100%, 160%) rotate(45deg);
    }
    .PDFRectTwo {
        width: 350px;
        height: 350px;
        @include backBlue(90deg);
        transform: translate(130%, -50%) rotate(45deg);
        .PDFinternalRectTwo {
            transform: translate(10%, 0%);
            width: 320px;
            height: 320px;
            @include backBluelight(60deg);
        }
    }
    .PDFRectThree {
        width: 200px;
        height: 200px;
        @include backBlue(100deg);
        transform: translate(-40%, 20%) rotate(45deg);
    }
    .PDFRectFour {
        width: 250px;
        height: 270px;
        @include backGray(100deg);
        transform: translate(30%, -70%) rotate(45deg);
    }
    .PDFRectFive {
        width: 300px;
        height: 300px;
        @include backGrayLight(45deg);
        transform: translate(165%, -360%) rotate(45deg);
        box-shadow: 1px 1px 5px #becbd3;
    }
    .PDFRectSix {
        width: 300px;
        height: 300px;
        @include backGray(40deg);
        transform: translate(150%, -380%) rotate(45deg);
        box-shadow: 1px 1px 5px #becbd3;
        .PDFInternalRectSix {
            transform: translate(10%, -37%);
            width: 320px;
            height: 320px;
            border: 18px solid #fff;
            box-shadow: 20px -20px 15px 15px $greyBase;
        }
    }
    .PDFRectSeven {
        width: 300px;
        height: 300px;
        background-color: #ffffff;
        transform: translate(180%, -560%) rotate(45deg);
        box-shadow: 1px 1px 5px #becbd3;
        .PDFInternalRectSeven {
            width: 200px;
            height: 200px;
            transform: translate(15%, 35%);
            @include backBlue(135deg);
            .PDFsubInternalSeven {
                width: 180px;
                height: 180px;
                transform: translate(15%, -3%);
                @include backBluelight(45deg);
            }
        }
    }
    .PDFRectEight {
        width: 120px;
        height: 120px;
        background-color: #ffffff;
        transform: translate(420%, -1340%) rotate(45deg);
        box-shadow: 1px 1px 5px #becbd3;
        .PDFInternalRectEight {
            width: 80px;
            height: 80px;
            transform: translate(25%, 25%);
            @include backBlue(135deg);
            .PDFsubInternalEight {
                width: 50px;
                height: 50px;
                transform: translate(30%, 30%);
                @include backBluelightMedium(45deg);
            }
        }
    }
    .PDFRectNine {
        width: 200px;
        height: 200px;
        @include backBlue(105deg);
        transform: translate(180%, -780%) rotate(45deg);
    }
    .PDFRectTen {
        transform: translate(100%, -810%) rotate(45deg);
        width: 180px;
        height: 180px;
        border: 18px solid #fff;
        background-color: transparent;
        box-shadow: 1px 1px 5px #becbd3;
    }
    .PDFRectEleven {
        width: 170px;
        height: 170px;
        @include backGrayLighter(85deg);
        transform: translate(40%, -1125%) rotate(45deg);
        box-shadow: 1px 1px 5px #becbd3;
        .PDFInternalRectEleven {
            width: 100px;
            height: 100px;
            transform: translate(35%, 35%);
            @include backBlue(125deg);
            .PDFsubInternalEleven {
                width: 60px;
                height: 60px;
                transform: translate(30%, 30%);
                @include backBluelightMedium(135deg);
            }
        }
    }
    .PDFRectTwelve {
        width: 170px;
        height: 170px;
        @include backGray(85deg);
        transform: translate(140%, -1165%) rotate(45deg);
        box-shadow: 1px 1px 5px #becbd3;
        .PDFInternalRectTwelve {
            width: 100px;
            height: 100px;
            transform: translate(25%, 25%);
            @include backGrayLighter(125deg);
            box-shadow: 1px 1px 5px #becbd3;
        }
    }
    .PDFRectThirteen {
        width: 100px;
        height: 100px;
        @include backBluelightMedium(130deg);
        transform: translate(410%, -2265%) rotate(45deg);
        box-shadow: 1px 1px 5px #1176b4;
        .PDFInternalRectThirteen {
            width: 65px;
            height: 65px;
            transform: translate(28%, 28%);
            @include backGrayLighter(125deg);
            box-shadow: 1px 1px 5px #becbd3;
        }
    }
    .PDFRectFourteen {
        width: 80px;
        height: 80px;
        @include backBluelightMedium(130deg);
        transform: translate(355%, -3110%) rotate(45deg);
        .PDFInternalFourteen {
            width: 50px;
            height: 50px;
            transform: translate(30%, 30%);
            @include backGrayLighter(125deg);
            box-shadow: 1px 1px 5px #becbd3;
        }
    }
    .PDFRectFifteen {
        width: 80px;
        height: 80px;
        @include backGrayLighter(130deg);
        transform: translate(-20%, -3110%) rotate(45deg);
        .PDFInternalFifteen {
            width: 50px;
            height: 50px;
            transform: translate(30%, 30%);
            @include backBluelightMedium(125deg);
            box-shadow: 1px 1px 5px #becbd3;
        }
    }
    .PDFHeader {
        border-bottom: 3px solid #0073df;
        width: 100%;
        height: 60px;
        .PDFTitlePage {
            color: #025088;
            font-size: 40px;
            margin-left: 15px;
        }
        .PDFNoPage {
            width: 70px;
            float: right;
            height: 64px;
            margin-top: -48px;
            margin-right: 18px;
            @include backBlue(0);
            transform: skew(30deg);

            .PDFNumber {
                color: #ffffff;
                font-size: 40px;
                transform: skew(-30deg);
                margin-top: 15px;
                margin-left: 15px;
            }
        }
    }
    .PDFFooter {
        text-align: center;
        background-color: #0073df;
        color: #becbd3;
        padding-top: 15px;
        width: 100%;
        height: 30px;
        position: absolute;
        bottom: 0px;
    }
}
