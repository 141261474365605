.ContainerIconExecution {
  width: 100%;
  color: #fff !important;
  border-radius: 7px;

  .IconExecution {
    margin: auto;
    width: 120px;
    height: 120px;
    color: #fff !important;
  }
}
.online-agent {
  color: rgba(0, 252, 45, 1);
}
.offline-agent {
  color:  rgba(252, 0, 0, 1);

}
