.ItemList{
width: 100%;
display: block;
    border-radius: 10px;
    background-color: #17141d;
    margin-top: 7px;
    margin-bottom: 7px;
}
.arrow {
    color: white;
    background: #c59d4896;
    font-size: 3rem;
    height: 150px;
    cursor: pointer;
    margin-top: 100px;
    display: inline-block;
    width: 2vw;

    box-shadow: 3rem rgb(71, 1, 88);
    &.after {
        float: left;
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
    }
    &.before {
        float: right;
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
    }
}
.arrow:hover {
    background: #c59d48;
}

.Contain {
    height: 350px;
    width: 90%;
    display: inline-block;
    margin-top: 0px;
    overflow-x: auto;
    white-space: nowrap;
    text-align: center;
    
}
.card {
    display: inline-block;
    height: 280px;

    min-width: 180px;
    max-width: 180px;
    background-color: #17141d;
    border-radius: 10px;
    box-shadow: -1rem 0 1rem #000;
    transition: 0.4s ease-out;
    position: relative;
    left: 0px;
    margin-top: 30px;
    padding-top: 15px;
}

.card:not(:first-child) {
    margin-left: -50px;
}

.card:hover {
    transform: translateY(-20px);
    transition: 0.4s ease-out;
    z-index: 12;
    box-shadow: 0rem 0rem 2rem rgba(255, 255, 255, 0.267);

}

.card:hover ~ .card {
    position: relative;
    left: 50px;
    transition: 0.4s ease-out;
}

.title {
    color: white;
    font-weight: bold;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: calc( 90% - 50px );
    max-width: 90%;
}

.checkCustom{

    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 4px solid #fff;
    margin-left: 7px;
    margin-top: 7px;
    display: inline-block;
}
.checkCustom:hover{
    border-color: #c59d48;
}

