.GeneralModal {
    background-color: #242933;
    border: 1px solid #090f1a;
    box-shadow: 18px 4px 35px rgba(0, 0, 0, 0.15);
    text-align: center;
    overflow: auto;
    padding: 40px;
    border-radius: 12px;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    font-size: calc(16px + 2vmin);
}
