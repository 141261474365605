@use "Colors";

$fontFamilyGlobal: "Roboto", sans-serif;
$fontSizeTitles: 2vw;

@mixin FontsTitles{
    font-family: $fontFamilyGlobal;
    font-size: $fontSizeTitles;
    color: Colors.$color-textTitles;
    font-weight: 600;
}
