.MuiStepLabel-label.MuiStepLabel-active {
    color: white;
    font-weight: 500;
  }
  .MuiStepLabel-label {
    color: white;
}
.MuiStepLabel-label.MuiStepLabel-completed {
    color: blue;
    font-weight: 500;
}
.MuiStepLabel-label.MuiStepLabel-active {
  color: white;
  font-weight: 500;
}
.ButtonFilter{
  color: white;
}
.Inputiincurtion{
  width: 50%;
.MuiInputBase-input {
  color: white; 
}}
.MuiInputLabel-filled.MuiInputLabel-shrink {
  transform: translate(12px, 10px) scale(0.75);
  color: white;
}
.wrapper {
  display: grid;
  max-height: 300px;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
  overflow: auto;
}
.wrapperArtefact{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(10px, auto);

}
.chequete{
  margin-left: 0px;
}
.superior{

  .inferior{
    display: none !important;
    width: 50px;
    height: 50px;
    background-color: aqua;
  }
}
.superior:hover{
  
  .inferior{
    display: block !important;
  }
}
.textfield{
  margin-top: 16px;
  
  text-align: center;
  input{
    
    font-weight: 600;
    transition: all 0.3s;
    color: #fff;
    font-size: 14px;
    
  }
  
}

