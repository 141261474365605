.SizeBar{
    float: right;
    display: flex;
    margin-top: -10px;  
    margin-right: 7px;
    background-color: #090f1a;
    .Item{
        display: inline-block;
        height: 15px;
        width: 15px;
        margin:3px;
        border-radius: 50%;
        cursor: pointer;
         
    }
    & .Min{
        background: rgb(255,255,255);
        background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(227,40,175,1) 100%);
    }
    & .Stand{
        background: rgb(255,255,255);
        background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(71,173,250,1) 100%);
    }
    & .Max{
        background: rgb(255,255,255);
        background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(140,48,255,1) 100%);
    }
    & .Min:hover{
        background: rgba(227,40,175,1);
    }
    & .Stand:hover{
        background: rgba(71,173,250,1);
    }
    & .Max:hover{
        background: rgba(140,48,255,1);
    }
    
}
.ContainerGrid{
    background-color: #090f1a;
    border-radius: 14px;
    padding: 7px;
    border:10px solid #090f1a;
}