.ContentStepOne{
    width: 100%;
    text-align: center;
}
.ContainerCards{
     text-align: center;
      background-color: #11172E;
       padding: 10px; 
       border-radius: 14px;
}
.BoxCard{
    display: inline-block;
    margin-left:15px;
    min-width: 150px;
    max-width: 150px;
    text-align: center;
}
.CardEnpoint{
    width: 100%;
    height: 200px;
    display: block;
    border-radius: 10px;
    margin-top: 7px;
    margin-bottom: 7px;
    padding: 7px;
}
.InlineBorder{
    border: 3px solid;
    border-radius: 10px;
    height: 100%;
}
.Ttlcard{
    text-align: center;
    cursor: pointer;
    font-size: 12px;
    margin-top: 15px;
    max-width: 100px;
}
.IconClass{
        width: 40px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 15px;
}
.IOCNameContent{
    margin-top: 15px;
    margin-bottom: 15px;

}