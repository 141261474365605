.content-main-section {
  margin-left: 20px;

  padding-bottom: 15px;
  transition: all 0.3s;
  height: 100%;
}

.dash-top-tlt {
  margin-top: 6px;
  font-size: 24px;
  color: #ffffff;
  font-weight: 500;
}

.contextbox {
  margin-bottom: 20px;
}

.executions-tabs .nav-link {
  white-space: nowrap;
  padding: 12px 30px;
}
.executions-tabs-records li {
  max-width: fit-content;
}
.executions-tabs-records li a {
  width: 215px;
}
.executions-tabs ul {
  display: flex;
  white-space: normal;
  overflow-x: unset;
  padding-bottom: 0;
}

.executions-tabs ul li:first-child .nav-link {
  border-radius: 12px 0px 0px 12px !important;
}

.executions-tabs ul li:last-child .nav-link {
  border-radius: 0px 12px 12px 0px !important;
}

@media (max-width: 491px) {
  .executions-tabs.executions-tabs-records li a {
    width: 180px;
    padding: 12px 15px;
  }
}

@media (max-width: 421px) {
  .executions-tabs.executions-tabs-records li a {
    width: auto;
    padding: 12px 15px;
  }

  .executions-tabs-records li {
    max-width: 100%;
  }

  .src-left-box.executions-tabs.executions-tabs-records li a {
    border-radius: 12px !important;
    margin: 5px 0;
  }

  .bg-cycle-iner img {
    width: 50px;
  }
}

.src-left-box .nav-link {
  background-color: #0a0818;
  border: 1px solid #1e1c3a;
  box-shadow: 0px 10px 20px rgb(0 0 0 / 5%);
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  border-radius: 0;
  padding: 12px 14px;
  text-transform: capitalize;
  border-left: 0;
}

.src-left-box ul li:first-child .nav-link {
  border-radius: 12px 0px 0px 12px;
  border-left: 1px solid #1e1c3a;
}

.src-left-box ul li:last-child .nav-link {
  border-radius: 0px 12px 12px 0px;
}

.src-left-box .nav-link.active {
  background-color: #c59d48;
  border-color: #c59d48;
}

.src-left-box ul {
  display: block;
  white-space: nowrap;
  overflow-x: auto;
  padding-bottom: 10px;
}

.src-left-box li {
  display: inline-block;
}

.src-left-box .nav-link {
  border: 1px solid #1e1c3a !important;
  border-radius: 12px !important;
}

.export-btn-new {
  background-color: #c59d48;
  border: 1px solid #1e1c3a;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #ffffff !important;
  outline: none;
  display: inline-block;
  padding: 10px 30px 12px 30px;
}

.export-btn-new img {
  max-width: 20px;
  max-height: 20px;
  object-fit: contain;
  object-position: center;
  position: relative;
  top: -2px;
}

.export-btn-new {
  padding: 12px 20px 13px 20px;
  font-size: 14px;
}

@media (max-width: 575px) {
  .dropdown-file-list .export-btn-new {
    padding: 12px 17px 13px 17px;
  }
}

.flir-section-hide {
  position: absolute;
  right: 0;
  z-index: 1;
  background-color: #c59d48;
  border: 1px solid #1e1c3a;
  padding: 15px;
  box-shadow: 18.9414px 4.2092px 36.8305px rgba(0, 0, 0, 0.45);
  border-radius: 12px;
  backdrop-filter: blur(22.868px);
  width: 450px;
  margin-top: 10px;
  display: none;
}

.flir-section-hide-inr bdi {
  font-size: 18px;
  line-height: 34px;
  color: #ffffff;
  font-weight: 600;
  display: block;
  text-align: left;
  padding-bottom: 15px;
}

.flir-section-hide {
  width: 390px;
}

.flir-section-hide {
  width: 300px;
  left: -55px;
}

.flir-section-hide-inr bdi {
  font-size: 18px;
  line-height: 34px;
  color: #ffffff;
  font-weight: 600;
  display: block;
  text-align: left;
  padding-bottom: 15px;
}

.step-section-info-inr .comn-form span {
  display: block;
  font-size: 16px;
  text-transform: uppercase;
  color: #c7c7c7;
}

.frm-grp-mb {
  margin-bottom: 25px;
}

.frm-grp-mb {
  margin-bottom: 20px;
}

.comn-label-dash {
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 1px;
  color: #c7c7c7;
  font-weight: 500;
  font-family: "Open Sans";
  margin-bottom: 15px;
}

.rt-log-box .form-control::-webkit-input-placeholder {
  color: #fffc;
}

.rt-log-box .form-control::-moz-placeholder {
  color: #fffc;
}

.rt-log-box .form-control:-ms-input-placeholder {
  color: #fffc;
}

.rt-log-box .form-control:-moz-placeholder {
  color: #fffc;
}

.rt-log-box .form-control {
  background-color: #242933;
  border: 1px solid #464366;
  border-radius: 14px;
  font-weight: 600;
  transition: all 0.3s;
  height: 55px;
  color: #fff;
  font-size: 14px;
}

.rt-log-box .form-control:focus {
  border-color: #c59d48;
  box-shadow: 0 0 3px #c59d48;
  color: #c59d48;
}

.hdr-top-box-inr-main .form-control {
  background-color: #242933;
  border-radius: 35.1324px;
  border: 0;
  box-shadow: none;
  outline: none;
  font-size: 14px;
  color: #fff;
  padding: 10px 12px 10px 50px;
  height: 50px;
  font-weight: 500;
}

.hdr-top-box-inr-main .form-control::-webkit-input-placeholder {
  color: #06162466;
}

.hdr-top-box-inr-main .form-control::-moz-placeholder {
  color: #06162466;
}

.hdr-top-box-inr-main .form-control:-ms-input-placeholder {
  color: #06162466;
}

.hdr-top-box-inr-main .form-control:-moz-placeholder {
  color: #06162466;
}

.input-style-dash {
  background-color: #242933;
  border: 1px solid #464366;
  border-radius: 14px;
  font-weight: 600;
  transition: all 0.3s;
  height: 55px;
  color: #fff;
  font-size: 14px;
  
}

.input-style-dash:focus {
  border-color: #c59d48;
  box-shadow: 0 0 3px #c59d48;
  background-color: #242933;
  color: #c59d48;
}

.input-style-dash::-webkit-input-placeholder {
  color: #66638f;
}

.input-style-dash::-moz-placeholder {
  color: #66638f;
}

.input-style-dash:-ms-input-placeholder {
  color: #66638f;
}

.input-style-dash:-moz-placeholder {
  color: #66638f;
}

.apply-btn-new {
  background-color: #c59d48;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  font-size: 14px;
  line-height: 26px;
  color: #ffffff;
  border: 0;
  outline: none;
  padding: 7px;
}

.MuiDialogContent-root {
  background-color: #242933;
  .MuiTypography-root {
    color: #fff;
  }
  .MuiGrid-root {
    color: #fff;
  }
  .css-ow97ht-MuiTypography-root.Mui-selected {
    color: #c59d48;
  }
  .css-1dozdou {
    color: #fff;
    fill: #fff;
  }
  .css-i4bv87-MuiSvgIcon-root {
    color: #fff;
    fill: #fff;
  }
  .css-qhrdzm-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day{
    color:#Fff;
  }
}
.MuiDialogActions-root {
  background-color: #242933;
}
