.container {
  padding: 16px;
  text-align: center;
  background-color: #1e2833;
  border: 1px solid white;
  width: 95%;
  border-radius: 8px;
  padding-bottom: 40px;
  .itemComponentok {
    border-radius: 4px;
    border: 1px solid rgb(20, 5, 61);
  }
  .itemComponentok:hover {
    position: relative;
    .Component_Div {
      position: absolute;
      z-index: -9;
    }
  }
}
.dash-top-tlt {
  // padding: 15px 0;
}

.dash-top-tlt div {
  color: #ffffff;
  font-weight: 400;
  margin-bottom: 0;
}

.dash-top-tlt div img {
  width: 22px;
  margin-right: 10px;
}

.dash-top-tlt div {
  font-size: 22px;
}

.dash-top-tlt div img {
  width: 20px;
}

.dash-top-des p {
  padding: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  font-size: 18px;
  color: #66638f;
  float: right;
}

.dash-agent-bx {
  background-color: #0a0818;
  border-radius: 14.4483px;
  padding: 15px;
  height: 100%;
}

.dash-agent-bx span {
  display: block;
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 5px;
}

.dash-agent-bx bdi {
  display: block;
  font-weight: 700;
  font-size: 30px;
  color: #ffffff;
}

.dash-agent-bx mark {
  display: block;
  font-size: 14px;
  color: #ffffff;
  padding: 0;
  margin-top: 5px;
  background-color: transparent;
}

.execu_results p {
  font-weight: 500;
  font-size: 15px;
  color: #a5a5a5;
  margin-top: 10px;
}

.execu_results {
  width: 100%;
  text-align: center;
}

.dash-cmn-box {
  background-color:#090f1a;
margin-left: -7px;
  .MuiTextField-root {
    .css-129jzy1-MuiInputBase-root-MuiOutlinedInput-root{
      border: 1px solid white
    }
        .MuiOutlinedInput-input {
      
      color: white;
    }
    .MuiSvgIcon-root {
      fill: white;
    }
  }
}

.dashbord_main_title {
  font-weight: 430;
  font-size: 18px;
  color: #ffffff;
}
.dash-agent-right div {
  height: 100px;
  object-fit: contain;
  object-position: center;
}
.dashboardTable {
  background-color: #0a0818;
  border-radius: 14.4483px;
  height: 100%;
  .CountLabel {
    display: inline-block;
    width: 45%;
    text-align: center;
    vertical-align: middle;
  }
  .Barra {
    display: inline-block;
    width: 5%;
    height: 40px;
    margin-top: -5px;
    border-radius: 14.5px;
    vertical-align: middle;
    &.Success {
      background-color: #0f0;
    }
    &.NotSend{
      background-color: #ff0;
    }
    &.Fail{
      background-color: #f00;
    }
  }
}
