.graph {
    border-radius: 50%;
    width: 120px;
    height: 120px;
    border: 4px solid white;
    display: inline-flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    color: white;
    margin-bottom: 15px;
    animation-name: widthEffect;
    animation-duration: 1s;
}
#GaugeCounterSuccessAndFail {
    tspan {
        fill: white !important;
    }
}
.endpoint {
    border-color: #0073ff;
    background-color: #33609777;
}
.failed {
    border-color: #c10303;
    background-color: #60262677;
}
.success {
    border-color: #08b102;
    background-color: #356f3377;
}
@keyframes widthEffect {
    from {
        width: 0px;
        height: 0px;
    }
    to {
        width: 120px;
        height: 120px;
    }
}
