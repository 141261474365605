$ColorAttack:#790000;
$ColorDefend:#1e05ad;
@mixin boxShadowstatus($paint) {
  -webkit-box-shadow: 0px 0px 1px 3px $paint;
  box-shadow: 0px 0px 10px 3px $paint;
}

@mixin ContentBody() {
  width: 100%;
  padding: 15px;
  border-radius: 14px;
}
.BackAttack {
  @include ContentBody();
  @include boxShadowstatus($ColorAttack);
}
.BackDefend { 
  @include ContentBody();
  @include boxShadowstatus($ColorDefend);
}
.TitleAttack {
  display: inline-block;
  color: #fff;
}
.TitleDefend {
  display: inline-block;
  color: #fff;
}

.ExecStatus {
  border-radius: 14px;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 14px 10px;
  width: 125px;
}
.DashboardReport {
  padding: 15px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 4%);
  border-radius: 12px;
  margin-bottom: 24px;
  text-align: center;
  margin-top: 24px;
  &.Attack{
    @include boxShadowstatus($ColorAttack);
  }
  &.Defend{
    @include boxShadowstatus($ColorDefend);
  }
  
}
.HandleButtons{
  width: 215px;
  height: 47px;
  border-radius: 12px 0px 0px 12px;
  white-space: nowrap;
  padding: 12px 30px;
  box-shadow: 0px 10px 20px rgb(0 0 0 / 5%);
  font-weight: 600;
  font-size: 14px;
  border: 1px solid;
}
.Mitreline {
  margin: auto;
  padding: 16px;
  text-align: center;
  background-color: #1e2833;
  width: 95%;
  height: 100%;
  border-radius: 8px;
  .circle {
    width: 110px;
    height: 110px;
    float: left;
    z-index: 3;
    border: 8px solid black;
    border-radius: 50%;
    background-color: whitesmoke;
    .circleContent {
      margin: auto;
      color: #214a80;
      font-size: medium;
      word-wrap: break-word;
      font-weight: bold;
      margin-top: 30%;
      margin-bottom: auto;
    }
  }
  .Conect {
    width: 50px;
    height: 20px;
    background-color: whitesmoke;
    float: left;
    z-index: 1;
    margin-top: 50px;
  }
}

@keyframes autoProgress {
  0% {
    width: 0%;
    background-color: transparent;
  }

  100% {
    width: 100%;
    background-color: #34c3e38e;
  }
}

.progress-bar {
  background-color: #1a1a1a;
  height: 45px;
  width: 450px;
  margin: 50px auto;
  border-radius: 16px;
  box-shadow: 0 1px 5px #000 inset, 0 1px 0 #444;
}

.stripes {
  background-size: 30px 30px;
  background-color: white;
}

.progress-bar-inner {
  display: block;
  height: 45px;
  width: 0%;
  background-color: #34c3e38e;
  border-radius: 16px;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;
  position: relative;
  animation: autoProgress 1s infinite linear;
}

.life-cycle-box .box-title span {
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
  display: block;
}

.life-cycle-box .box-title.green-title span {
  color: #38e25d;
}

.life-cycle-box .box-title.red-title span {
  color: #ff4747;
}

.life-cycle-box .box-title.blue-title span {
  color: #3c60ff;
}

.life-cycle-box .box-title.yellow-title span {
  color: #ffab2d;
}

.life-cycle-box .box-title.purple-title span {
  color: #8c30ff;
}

.life-cycle-box .box-title.orange-title span {
  color: #ff7e35;
}

.life-cycle-box {
  padding: 10px;
  background-color: transparent;
  border-radius: 5px;
  display: inline-block;
  width: 100%;
  min-height: 175px;
  position: relative;
}

.life-cycle-box.green-box {
  background-color: #183026;
}

.life-cycle-box.red-box {
  background-color: #3c1111;
}

.life-cycle-box.blue-box {
  background-color: #182830;
}

.life-cycle-box.yellow-box {
  background-color: #231b0e;
}

.life-cycle-box.purple-box {
  background-color: #261738;
}

.life-cycle-box.orange-box {
  background-color: #301b0f;
}

.life-cycle-list ul li {
  color: #fff;
  font-family: "Open Sans";
  font-weight: 500;
  font-size: 13px;
  margin-bottom: 5px;
  width: unset;
  list-style-type: circle;
}

.life-cycle-list ul {
  padding-left: 20px;
  list-style-type: square;
}

.life-cycle-box-main {
  margin-top: 150px;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  align-items: center;
  
}

.life-cycle-box-main li {
  width: 16.66%;
  padding-right: 15px;
  padding-left: 15px;
  list-style: none;
}

.top-line-life-cycle {
  position: absolute;
  left: 40%;
  top: -147px;
}

.bdr-row {
  position: relative;
  padding: 15px;
}

.bdr-row:before {
  position: absolute;
  content: "";
  border: 4px dashed #38e25d;
  left: 0;
  bottom: 0;
  height: 48%;
  right: 0;
  border-radius: 0 0 9px 9px;
  border-top: 0;
  z-index: 1;
}

.bdr-row:after {
  position: absolute;
  content: "";
  border: 4px solid #38e25d;
  left: 0;
  top: 0;
  height: 48%;
  right: 0;
  border-radius: 9px 9px 0 0;
  border-bottom: 0;
  z-index: 1;
}

.bdr-row-color-2:after {
  border-color: #ff4747;
  border-style: dashed;
}

.bdr-row-color-2:before {
  border-color: #ff4747;
  border-style: solid;
}

.bdr-row-color-3:after {
  border-color: #3c60ff;
  
}

.bdr-row-color-3:before {
  border-color: #3c60ff;
}

.bdr-row-color-4:after {
  border-color: #ffab2d;
  border-style: dashed;
}

.bdr-row-color-4:before {
  border-color: #ffab2d;
  border-style: solid;
}

.bdr-row-color-5:after {
  border-color: #8c30ff;
  border-style: solid;
}

.bdr-row-color-5:before {
  border-color: #8c30ff;
}

.bdr-row-color-6:after {
  border-color: #ff7e35;
  border-style: dashed;
}

.bdr-row-color-6:before {
  border-color: #ff7e35;
  border-style: solid;
}

.green-bg {
  position: relative;
}

.green-bg::after {
  content: "";
  position: absolute;
  height: 4px;
  width: 23px;
  right: -4px;
  top: 48%;
  background-color: #38e25d;
}

.red-bg::after {
  background-color: #ff4747;
  bottom: 48%;
  top: auto;
}

.blue-bg::after {
  background-color: #3c60ff;
}

.yellow-bg::after {
  background-color: #ffab2d;
  bottom: 48%;
  top: auto;
}

.purple-bg::after {
  background-color: #8c30ff;
}

.orange-bg::after {
  background-color: #ff7e35;
  height: 0;
}

.green-bg::before {
  content: "";
  position: absolute;
  right: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #38e25d;
  top: 44%;
  right: -13px;
  z-index: 1;
}

.red-bg::before {
  border-left-color: #ff4747;
  bottom: 44%;
  top: auto;
}

.blue-bg::before {
  border-left-color: #3c60ff;
}

.yellow-bg::before {
  border-left-color: #ffab2d;
  bottom: 45%;
  top: auto;
}

.purple-bg::before {
  border-left-color: #8c30ff;
}

.orange-bg::before {
  border-left-color: #ff7e35;
  height: 0;
  border: 0;
}

.dlt-info-div p {
  font-size: 18px;
  color: #ffffff;
  font-weight: 500;
}

.life-cycle-suggest-inr {
  display: none;
}

.box-bubble {
  background: #b69141;
  border: 1px solid #b69141cc;
  padding: 10px;
  text-align: center;
  font-family: "Open Sans";
  color: #fff;
  position: absolute;
  top: -50px;
  left: 50%;
  right: 50%;
  transform: translate(-70%, -50%);
  width: 150px;
  border-radius: 8px;
}

.box-bubble.sb1:before {
  content: "";
  width: 18px;
  height: 18px;
  -webkit-transform: scaleX(0.75) rotate(36deg);
  -ms-transform: scaleX(0.75) rotate(36deg);
  transform: scaleX(0.7) rotate(42deg);
  position: absolute;
  bottom: -9px;
  background-color: #b69141;
  right: 13px;
  border-left: 1px solid transparent;
  border-top: 1px solid transparent;
  border-right: 1px solid #b69141cc;
  border-bottom: 1px solid #b69141cc;
}

.life-cycle-suggest .line-thumb {
  position: relative;
  width: 25%;
  display: inline-block;
}

.life-cycle-suggest .line-thumb.yellow-thumb {
  width: 75%;
}

.life-cycle-suggest .line-thumb::after {
  content: "";
  height: 30px;
  width: 2px;
  position: absolute;
  right: 0;
  left: auto;
  top: 0;
  bottom: 0;
  margin: auto 0;
  transform: translate(0, -70%);
}

.life-cycle-suggest .line-thumb::before {
  content: "";
  height: 30px;
  width: 2px;
  background-color: #ffab2d;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  transform: translate(0, -70%);
}

.life-cycle-suggest .yellow-thumb::after,
.life-cycle-suggest .yellow-thumb::before {
  background-color: #38e25d;
}

.line-thumb .yellow-line {
  border: 1px dashed #ffab2d;
  position: relative;
}

.line-thumb .yellow-line::after {
  content: "";
  position: absolute;
  right: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #ffab2d;
  top: -10px;
}

.line-thumb .yellow-line::before {
  content: "";
  position: absolute;
  left: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #ffab2d;
  top: -10px;
}

.line-thumb .yellow-line.green-line::after {
  border-left-color: #38e25d;
}

.line-thumb .yellow-line.green-line::before {
  border-right-color: #38e25d;
}

.line-thumb .green-line {
  border: 1px dashed #38e25d;
}

.line-thumb span {
  font-size: 18px;
  font-weight: 600;
  font-family: "Open Sans";
  color: #ffab2d;
}

.line-thumb .green-txt-response span {
  color: #38e25d;
}
