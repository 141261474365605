.iconAnimated {
    animation: spin 2s linear infinite;
}
@keyframes spin {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.textAnimated{
    animation: fade 2s linear infinite;
}
@keyframes fade {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
    
}