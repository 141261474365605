@use "../../Scss/Colors";

@mixin core-rules {
    display: flex;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    border: 1px dashed Colors.$border-colorInputFileBase;

    :hover {
        cursor: pointer;
    }

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    label {
        margin-left: 15px;
        margin-right: auto;
        font-size: medium;
        margin-top: 10px;        
        width: 50%;
        
    }

    p {
        margin-left: auto;
        margin-right: auto;
    }

    #secondary-button {
        margin-left: auto;
        margin-right: 0;
        width: 50%;
        background-color: Colors.$background-buttonAction;
    }
}

.file-upload {
    @include core-rules;
}

.file-upload:hover {
    @include core-rules;
    border: 2px dashed Colors.$border-colorInputFileHover;

    input {
        width: 100%;
        height: 50px;
        position: absolute;
        z-index: 100005;
        
    }
}
