.GeneralContent {
  background-color: #0a0818;
  border-radius: 14.4483px;
  padding: 15px;
  margin: 7px 0px 0px 0px;
  width: 100%;
  .InterpretationBody {
    text-align: justify;
    max-height: 400px;
    overflow-y: auto;
    color: darkgray;
  }
}

.ModalBox {
  display: inline-block;
  cursor: pointer;
}
.ModalButtonInterpretation {
  background-color: #b69141;
  line-height: 22px;
  color: #fff !important;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  .ImageIcon {
    vertical-align: middle;
  }
}
.ModalSettion {
  overflow: auto;
  max-height: 800px;
  background-color: #242933;
  padding: 20px;
  border-radius: 14px;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  box-shadow: 18px 4px 35px rgb(0 0 0 / 15%);
  font-size: calc(16px + 2vmin);
  border: 1px solid #090f1a;
  width: auto;
  min-width: 80%;
}
.ContainerListIcon {
  float: right;
  .ContainerIcon {
    display: inline-flex;
    width: 40px;
    height: 40px;
    margin: 7px;
    border-radius: 7px;
    .Icon {
      vertical-align: middle;
      margin: auto;
      color: #fff;
    }
  }
  & .Web {
    background-color: rgba(71, 173, 250, 1);
  }
  & .End {
    background-color: rgba(227, 40, 175, 1);
  }
  & .Exe {
    background-color: rgb(227, 40, 71);
  }
  & .Call {
    background-color: rgba(140, 48, 255, 1);
  }
}
#panel2bh-header {
  background-color: #21273f;
  color: #fff;
}
.AccordionStyle {
  color: #fff;
  background-color: #242933;
}
.AccordionText {
  color: #fff;
  max-height: 200px;
  overflow: auto;
  white-space: pre-wrap;
}
