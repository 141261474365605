.InputSearch {
    input {
      color: azure;
    }
  }
  .TagTest {
    border-radius: 14px;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 14px 10px;
    width: 125px;
    &.Good {
      color: #38e25d !important;
    }
    &.Midd {
      color: #ffab2d !important;
    }
    &.Fail {
      color: #ff4747 !important;
    }
  }
  .behaivorTitle {
    width: 100%;
    text-align: center;
    color: #fff;
    &.One {
      background-color: #ff4747;
    }
    &.Two {
      background-color: #ffab2d;
    }
    &.Three {
      background-color: #0000ff;
    }
  }
  .AddArtifact{
    overflow: auto;
   
  }
  
  .AddArtifact {
    max-height: 95vh;
    height: auto;
    background-color: #242933;
    padding: 40px;
    padding-top: 20px;
    border-radius: 14px;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    box-shadow: 18px 4px 35px rgba(0, 0, 0, 0.15);
    font-size: calc(16px + 2vmin);
    border: 1px solid #090f1a;
    width: 50%;
    .Header {
      padding: 15px 40px 0px 40px;
      .Title {
        font-size: 28px;
        font-weight: 700;
        font-size: 28px;
        color: #ffffff;
        display: block;
      }
    }
    .Body {
      padding: 40px;
      padding-top: 20px;
      overflow-y: auto;
      .TexTTitles {
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 1px;
        color: #c7c7c7;
        font-weight: 500;
        margin-bottom: 15px;
      }
      .InputContent {
        margin-bottom: 25px;
        flex: 0 0 100%;
        max-width: 100%;
        .ContainerOne {
          backdrop-filter: blur(34.2212px);
          border-radius: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .ContainerTwo {
            width: 100%;
            height: 100%;
            display: flex !important;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding-bottom: 1rem !important;
            padding-top: 1rem !important;
  
            .InputFile {
              color: #fff;
              opacity: 1;
              position: unset;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              width: auto;
              height: auto;
            }
          }
        }
        .ContainerOnePackage {
          background-color: transparent;
          border: 1px solid #aeb9ca80;
          backdrop-filter: blur(34.2212px);
          border-radius: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .ContainerTwo {
            width: 100%;
            height: 100%;
            display: flex !important;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding-bottom: 1rem !important;
            padding-top: 1rem !important;
  
            .InputFile {
              color: #fff;
              opacity: 1;
              position: unset;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              width: auto;
              height: auto;
            }
          }
        }
      }
      .SelectContent {
        margin-bottom: 25px;
        flex: 0 0 50%;
        width: 50%;
        font-size: 14px;
        font-weight: 400;
        display: inline-block;
        .SelectInput {
          background-color: #242933;
          border: 1px solid #464366;
          border-radius: 14px;
          font-weight: 600;
          transition: all 0.3s;
          height: 55px;
          color: #fff;
          font-size: 14px;
          width: 100%;
          color: #fff;
          .SelectItem {
            font-weight: 600;
            color: #fff;
            font-size: 14px;
          }
          .SelectItem:hover {
            color: #c59d48;
          }
        }
        .SelectInput:hover {
          color: #c59d48;
          border: 1px solid #c59d48;
        }
      }
      .TextAreaContent {
        margin-bottom: 25px;
        flex: 0 0 100%;
        max-width: 100%;
        .ContainerOne {
          backdrop-filter: blur(34.2212px);
          border-radius: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .ContainerTwo {
            width: 100%;
            height: 100%;
            display: flex !important;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding-bottom: 1rem !important;
            padding-top: 1rem !important;
          }
        }
        .TextArea {
          min-height: 185px;
          max-height: 185px;
          background-color: #242933;
          border: 1px solid #464366;
          border-radius: 14px;
          font-weight: 600;
          transition: all 0.3s;
          color: #fff;
          font-size: 14px;
          display: block;
          width: 100%;
          padding: 0.375rem 0.75rem;
          line-height: 1.5;
          overflow: auto;
          resize: none;
          font-family: "Roboto","Helvetica","Arial",sans-serif;
        }
        .TextAreaResolution {
          background-color: #242933;
          border: 1px solid #464366;
          border-radius: 14px;
          font-weight: 600;
          transition: all 0.3s;
          min-height: 200px;
          max-height: 200px;
          color: #fff;
          font-size: 14px;
          padding: 0.375rem 0.75rem;
          line-height: 1.5;
          overflow: auto;
          resize: none;
        }
      }
      .SelectSystemContent {
        margin-bottom: 25px;
        flex: 0 0 100%;
        max-width: 100%;
        .SelectInput {
          background-color: #242933;
          border: 1px solid #464366;
          border-radius: 14px;
          font-weight: 600;
          transition: all 0.3s;
          height: 55px;
          color: #fff;
          font-size: 14px;
          width: 100%;
          color: #fff;
          .SelectItem {
            font-weight: 600;
            color: #fff;
            font-size: 14px;
          }
          .SelectItem:hover {
            color: #c59d48;
          }
        }
        .SelectInput:hover {
          color: #c59d48;
          border: 1px solid #c59d48;
        }
        .SelectInput:active {
          color: #c59d48;
          border: 1px solid #c59d48;
        }
      }
      .ShowButtonContent {
        margin-bottom: 1rem;
        flex: 0 0 100%;
        max-width: 100%;
  
        .ShowBox {
          padding-bottom: 1rem !important;
          .BoxButton {
            text-align: center;
            .ButtonShow {
              font-weight: 600;
              font-size: 16px;
              color: #ffffff;
              background-color: #090f1a;
              text-transform: capitalize;
            }
          }
          .CollapseContent {
            .OptionCollapse {
              display: block;
              flex-wrap: wrap;
              margin-right: -15px;
              margin-left: -15px;
              flex: 0 0 50%;
              max-width: 50%;
              .OptionBlock {
                display: block;
                .CheckBlock {
                  display: inline-block;
                  position: relative;
                  margin-bottom: 0;
                  cursor: pointer;
                  font-weight: 600;
                  font-size: 15px !important;
                  color: #c7c7c7;
                  user-select: none;
                }
              }
              .InputBlock {
                margin-bottom: 1rem;
                .InputText {
                  background-color: #242933;
                  border: 1px solid #464366;
                  border-radius: 14px;
                  font-weight: 600;
                  transition: all 0.3s;
                  height: 55px;
                  color: #fff;
                  font-size: 14px;
                }
              }
              .ContainerOne {
                backdrop-filter: blur(34.2212px);
                border-radius: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                .ContainerTwo {
                  width: 100%;
                  height: 100%;
                  display: flex !important;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;
                  padding-bottom: 1rem !important;
                  padding-top: 1rem !important;
  
                  .InputFile {
                    color: #fff;
                    opacity: 1;
                    position: unset;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: auto;
                    height: auto;
                  }
                }
              }
            }
          }
        }
      }
      .Footer {
        margin-left: auto;
        margin-right: auto;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
        .ButtonSection {
          text-align: center !important;
          .ButtonSave {
            border: 0;
            outline: none;
            font-weight: 600;
            font-size: 16px;
            color: #ffffff !important;
            display: inline-block;
            background-color: #c59d48;
            box-shadow: 0px 4px 8px rgb(0 0 0 / 12%);
            border-radius: 12px;
            padding: 14px;
            width: 100% !important;
          }
        }
      }
    }
  }
  .ArtifactButton {
    margin: 7px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #c59d48;
    cursor: pointer;
    .labelcontent {
      display: none;
      color: #fff;
    }
  }
  .ArtifactButton:hover {
    width: 150px;
    border-radius: 14px;
    background-color: #52169b;
    transition: width 1s;
    z-index: 10000px;
    position: relative;
    .labelcontent {
      margin-left: 7px;
      display: inline-block;
      animation: typewriter 1s normal;
    }
  }
  @keyframes typewriter {
    from {
      width: 0;
      color: transparent;
    }
    to {
      width: 50%;
      color: #c7c7c7;
    }
  }
  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, 0.75);
    }
    to {
      border-right-color: transparent;
    }
  }
  