@use '../../Scss/Fonts';

.module-page-title{
@include Fonts.FontsTitles;
}

.about-header {
  color: #8e97ad;
  font-weight: 500;
  font-size: 18px;
}

.con-box-style {
  width: "100%";
  background-color: #090f1a;
  border: 1px solid #1e1c3a;
  padding: 15px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 4%);
  border-radius: 12px;
  margin-top: 28px;
}

.container-about{
  padding-left: 1px;  
  display: inline-block;
  vertical-align: top;
  padding-top: 16px;
}

*img{
  max-width: 100%;
}
