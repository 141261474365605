td,
th span {
    text-align: center !important;
}
.dashbord_main_title {
    margin: 0px;
    font-weight: 430;
    font-size: 22px;
    color: #ffffff;
}

a::after {
    color: #ffffff !important;
    text-decoration: none;
}

.tableContent {
    padding: 10px;
    margin: 0px;
    width: 100%;
    height: 540px;
    font-size: 14px;
    background-color: #090f1a;
    border-radius: 0px 0px 12px 12px;
    overflow: auto;
}
