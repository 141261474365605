#timelineChartdiv{
  width: 100%;
  height: 100%;
  background-color: #090f1a;
  border-radius: 0px 0px 12px 12px;
}
.no_history {
    align-items: center;
    justify-content: center;
    height: 375px;
    display: flex;
  }
  .no_history p {
    font-weight: 500;
    font-size: 22px;
    text-align: center;
    color: #66638f;
  }
  .dashbord_main_title {
    font-weight: 500;
    font-size: 22px;
    color: #ffffff;
  }
  