.Body-box-style{
    width: "100%";
    background-color: #090f1a;
    border: 1px solid #1e1c3a;
    padding: 15px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 4%);
    border-radius: 12px;
    margin-top: 28px;
}

.Table-box-style{
    background-color: #0a0818;
    border: 1px solid #1e1c3a;
    border-radius: 8px;
    height: 200px;
    font-size: 12px;
    width: 100%;
    min-width: 100px;
    padding: 3px 15px;
    color: #8e97ad;
    margin-top: 10px;
    padding: 16px;
}