#AgentLocationMap{
  width: 100%;
  height: 100%;
  background-color: #090f1a;
  padding: 10px;
  border-radius:  0px 0px 12px 12px;
  }

  .pink-btn-cust {
    width: 180px;
    background-color: #c59d48;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    font-weight: 540;
    font-size: 22px;
    color: #ffffff !important;
    display: flex;
    padding: 10px 30px 12px 30px;
    border: 1px solid #c59d48;
    float: right;
    margin: auto;
  }
  
  
  .pink-btn-cust {
    padding: 12px 20px 13px 20px;
    font-size: 15.2px;
  
  }
  .pink-btn-cust > img {
    max-width: 18px;
    max-height: 15px;
    object-fit: contain;
    object-position: center;
    position: relative;
    top: 2px;
    margin-right: 5px;
  }
  