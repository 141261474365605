a:link,
a:visited,
a:active {
  color: #66638F;
  text-decoration: none;
}
main {
  background-color: #0a0818;
}

.menu-v3-class {
  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
    background-color: #c59d48;
  }
}