//Colores base de fondos
$background-down:#0a0818;
$background-paper:#c59d48;
$background-tableHeader:#1e1c3a;
$background-buttonAction:#c59d48;
$background-buttonPrimary:#c59d48;
$background-buttonSecandary:#242933;
$background-menuActive:#0d0b1f;
$background-menuBase:transparent;

//Colores base de letras
$color-textTitles:#fff;
$color-textContent:#8e97ad;
$color-textthreatZero:#8b0000;
$color-textthreatCritical:#6a0000;
$color-textthreatHigh:#ff0000;
$color-textthreatMidd:#ff5830;
$color-textthreatLow:#ffffa2;
$color-textthreatnone:#f3f3f3;
$color-textMenuBase:#66638f;
$color-textMenuActive:#c59d48;
$color-textOnlineAgent:#008000;
$color-textOfflineAgnet:#ffffffa6;

//Colores base Iconos
$fill-icon:#fff;
$fill-menuIcon:#66638f;
$fill-helpIconAgent:#ffa500;

//Chips
$border-colorChipAdmin:#9c27b0;
$border-colorChipUser:#1976d2;
$border-colorChipTested:#2e7d32;
$border-colorChipPartiallyTest:#ed6c02;
$border-colorChipUntested:#d32f2f; 
//InputFile colors
$border-colorInputFileBase:#66638f;
$border-colorInputFileHover:#c59d48; 
$border-colorInputFileError:#d32f2f; 