
.rt-Modal {
    max-height: 95vh;
    overflow: hidden;
    background-color: #242933;
    padding: 40px;
    padding-top: 20px;
    border-radius: 14px;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    box-shadow: 18px 4px 35px rgba(0, 0, 0, 0.15);
    font-size: calc(16px + 2vmin);
    border: 1px solid #090f1a;
    width: 70%;
  }
.rt-Input-Center {
  width: 350px;
  margin: auto;
  display: flex;
  justify-content: center;
  background-color: transparent;
  }
  .rt-TextAreaContent {
    margin-bottom: 25px;
    flex: 0 0 100%;
    max-width: 100%;
    .TextArea {
      height: 100%;
      background-color: #242933;
      border: 1px solid #464366;
      border-radius: 14px;
      font-weight: 600;
      transition: all 0.3s;
      color: #fff;
      font-size: 14px;
      display: block;
      width: 100%;
      padding: 0.375rem 0.75rem;
      line-height: 1.5;
      overflow: auto;
      resize: none;
    }
    .TextAreaResolution {
      background-color: #242933;
      border: 1px solid #464366;
      border-radius: 14px;
      font-weight: 600;
      transition: all 0.3s;
      height: auto;
      color: #fff;
      font-size: 14px;
      padding: 0.375rem 0.75rem;
      line-height: 1.5;
      overflow: auto;
      resize: none;
    }
  }
  .rt-SelectContent {
    margin-bottom: 25px;
    flex: 0 0 100%;
    max-width: 100%;
    .SelectInput {
      background-color: #242933 !important;
      border: 1px solid #464366;
      border-radius: 14px;
      font-weight: 600;
      transition: all 0.3s;
      height: 55px;
      color: #fff;
      font-size: 14px;
      width: 100%;
      color: #fff;
      .SelectItem {
        font-weight: 600;
        color: #fff;
        font-size: 14px;
        text-transform: capitalize;
      }
      .SelectItem:hover {
        color: #c59d48;
      }
    }
  }
  .rt-InputContent {
    margin-bottom: 25px;
    flex: 0 0 100%;
    max-width: 100%;
    .ContainerOne {
      backdrop-filter: blur(34.2212px);
      border-radius: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .ContainerTwo {
        width: 100%;
        height: 100%;
        display: flex !important;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 1rem !important;
        padding-top: 1rem !important;

        .InputFile {
          color: #fff;
          opacity: 1;
          position: unset;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          width: auto;
          height: auto;
        }
      }
    }
    .ContainerOnePackage {
      background-color: transparent;
      border: 1px solid #aeb9ca80;
      backdrop-filter: blur(34.2212px);
      border-radius: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .ContainerTwo {
        width: 100%;
        height: 100%;
        display: flex !important;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 1rem !important;
        padding-top: 1rem !important;

        .InputFile {
          color: #fff;
          opacity: 1;
          position: unset;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          width: auto;
          height: auto;
        }
      }
    }
  }
  .TagTest {
    border-radius: 14px;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 14px 10px;
    width: 125px;
    &.Good {
      color: #38e25d !important;
    }
    &.Midd {
      color: #ffab2d !important;
    }
    &.Fail {
      color: #ff4747 !important;
      
    }
  }
  .StepLabel{
      white-space: nowrap;
      overflow: hidden;
      word-break: break-all;
    max-width: 150px;
  }
  .StepIcon{
    border: 1.37962px solid #2e2d3a;
    z-index: 1;
    color: #fff;
    width: 50px;
    height: 50px;
    display: flex;
    border-radius: 13.74px;
    justify-content: center;
    align-items: center;
  }